<template>
  <div class="h-100 w-100 d-flex align-items-center justify-content-center bg-img">
    <vs-card class="table-responsive w-75">
        <div>
            <vs-row>
                <vs-col class="full-page-bg-color" vs-type="flex" vs-xs="0" vs-lg="6">
                    <img src="@/assets/images/pages/login.png" alt="login" class="m-auto">
                </vs-col>
                <vs-col vs-lg="6" vs-xs="12">
                    <router-view></router-view>
                </vs-col>
            </vs-row>
        </div>
    </vs-card>
  </div>
</template>

<script>
export default {
  name: 'Register',
}
</script>